import React from "react";

import { Button } from "components";
// import CircleDownIcon from "assets/icons/circle-down.svg";
import DiscordWhiteIcon from "assets/icons/discord-white.svg";
import "./serve.section.scss";
import { PlausibleEvents } from "../../config/constants";

const platform = require("platform");

const renderer = (label, title, subtitle, action, image) => {
  const handleClick = () => {};
  const handleDiscordCTA = PlausibleEvents.find(e => e.name === 'Discord-Button').event
  return (
    <div
      key={title}
      className="row align-items-stretch renderer text-center text-sm-start"
    >
      <div className="col-xs-12 col-sm-8 h-100 d-flex flex-column justify-content-center pe-2 pe-sm-5">
        <div
          className="label mb-3"
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          {label}
        </div>
        <div
          className="title mb-4"
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          {title}
        </div>
        <div
          className="subtitle mb-5"
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-delay="400"
          data-sal-easing="ease"
        >
          {subtitle}
        </div>
        <div
          className="action mb-4 mb-sm-0"
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-delay="600"
          data-sal-easing="ease"
        >
          <a
            target="_blank"
            rel="noreferrer"
            // href={`/download/${window.platform.os.family.toLocaleLowerCase()}`}
            href={`https://discord.com/invite/masq`}
          >
            <Button
              preIcon={action.preIcon}
              label={action.label}
              onClick={handleDiscordCTA}
            />
          </a>
          <div className="tip mt-2">{action.tip}</div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4 h-100 right-fluid">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

const Index = () => {
  const data = [
    {
      label: "SERVE THE NETWORK FASTER FOR BIGGER REWARDS",
      title: "The faster your upload speed, the more potential to earn",
      subtitle:
        "Each user will have a reputation on the MASQ Network, the faster you serve data to others, the better your reputation. MASQ reputation is also effected by how long you keep it running. The MASQ Network favours nodes with near-constant uptime.",
      action: {
        preIcon: DiscordWhiteIcon,
        label: "JOIN OUR DISCORD",
        // tip: `For ${window.platform.os.toString()} or later.`,
        tip: ``,
      },
      image: require("assets/images/radial-serve.png").default,
    },
    {
      label: "HELP THE ECOSYSTEM GROW INTO A BORDERLESS LANDSCAPE",
      title:
        "If MILLIONS of us share our connection, the Internet will become borderless.",
      subtitle: `This is about more than just earning cryptocurrency. This is about changing the landscape of the web forever, making sure no matter where a user lives, they have access to all open content available in the most private way that can be delivered. \n\nIt all starts with you. Choose a DNS provider and choose what you serve others!`,
      action: {
        preIcon: DiscordWhiteIcon,
        label: "JOIN OUR DISCORD",
        // tip: `For ${window.platform.os.toString()} or later.`,
        tip: ``,
      },
      image: require("assets/images/app-dashboard-consuming.png").default,
    },
    {
      label: "HELP SCALE UP THE POWER OF MASQ",
      title:
        "Run MASQ on command line – Spin up 100’s of cloud nodes on Virtual Private Servers",
      subtitle:
        "More of the technical type? You can run MASQ on command line and spin up instances across multiple virtual private servers – The more public IPs you have running MASQ, the more you could earn in MASQ utility tokens!",
      action: {
        preIcon: null,
        label: "RUN A COMMAND LINE NODE",
        tip: null,
      },
      image: require("assets/images/Terminal.png").default,
    },
  ];
  return (
    <div className="RunANode_serve">
      <div className="container">
        {data.map((item) => {
          return renderer(
            item.label,
            item.title,
            item.subtitle,
            item.action,
            item.image
          );
        })}
      </div>
    </div>
  );
};

export default Index;
